<template>
    <CTabs variant="pills">
        <CTab active class="mr-2">

            <template slot="title">
                <div class="p-2"> Pozisyon İşlemleri </div>
            </template>

            <CForm ref="form" class="needs-validation m-2 p-2 ">
                <CRow>
                    <CCol col="12">
                        <CInput
                            label="Pozisyon"
                            placeholder="Pozisyon Adı"
                            type="text"
                            v-model="data.name"
                            horizontal
                            required
                        />
                    </CCol>
                </CRow>

                <CRow>
                    <CCol col="12">
                        <CSelect
                            label="Departman"
                            placeholder="Departman"
                            horizontal
                            :options="departments"
                            :value.sync="data.departmentId"
                        />
                    </CCol>
                </CRow>

                <CRow>
                    <CCol></CCol>
                    <CCol>
                        <CButton 
                            color="success" 
                            style="width: 100%"
                            @click="savePosition"
                        > Kaydet</CButton>
                    </CCol>
                    <CCol></CCol>
                </CRow>

                <CDataTable
                    v-if="!loading"
                    :items="careerPositions"
                    :fields="fields"
                    :items-per-page="10"
                    hover
                    pagination
                    :loading="loading"
                    clickable-rows
                    @row-clicked="rowClicked"
                    v-on:refresh="refresh"
                    class="modalTable"
                >
                    <template #show_details="{item}">
                        <td class="py-2">
                            <CButton 
                                color="link" 
                                @click="openModalEvent('update', 'confirm', item, ' Pozisyon Güncelle', 'İlgili pozisyonun ismin <strong>'+item.name+'</strong> - <strong>'+departments.find( d => d.value == item.departmentId).label+'</strong> olarak düzenlemek istediğinize emin misiniz?')"> 
                                Güncelle 
                            </CButton>
                            <CButton 
                                color="link" 
                                style="color: red;"
                                @click="openModalEvent('delete', 'confirm', item, item.name + ' Sil', item.name+' isimli pozisyonu silmek istediğinize emin misiniz?')"> 
                                Sil 
                            </CButton>
                        </td>
                    </template>

                    <template #name="{item}">
                        <td>
                            <CInput v-model="item.name" />
                        </td>
                    </template>

                    <template #department="{item, i}">
                        <td>
                            <CSelect
                                :key="i"
                                placeholder="Departman"
                                horizontal
                                :options="departments"
                                :value.sync="item.departmentId"
                            />
                        </td>
                    </template>
                </CDataTable>
                
            </CForm>
        </CTab>
        <CTab >
            <template slot="title">
                <div class="p-2"> Departman İşlemleri </div>
            </template>

            <CForm ref="form" class="needs-validation m-2 p-2 ">
                <CRow>
                    <CCol col="12">
                        <CInput
                            label="Departman"
                            placeholder="Departman Adı"
                            type="text"
                            v-model="data.departmentName"
                            horizontal
                            required
                        />
                    </CCol>
                </CRow>

                <CRow>
                    <CCol></CCol>
                    <CCol>
                        <CButton 
                            color="success" 
                            style="width: 100%"
                            @click="saveDepartment"
                        > 
                            Kaydet
                        </CButton>
                    </CCol>
                    <CCol></CCol>
                </CRow>

                <CDataTable
                    :items="departments"
                    :fields="departmentsFields"
                    :items-per-page="10"
                    hover
                    pagination
                    :loading="loading"
                    clickable-rows
                    @row-clicked="rowClicked"
                    v-on:refresh="refresh"
                    class="modalTable"
                >
                    <template #show_details="{item}">
                        <td class="py-2">
                            <CButton 
                                color="link" 
                                @click="openModalEvent('updateDepartment', 'confirm', item, ' Departman Güncelle', 'İlgili departmanın ismini <strong>'+item.label+'</strong> olarak düzenlemek istediğinize emin misiniz?')"> 
                                Güncelle 
                            </CButton>
                            <CButton 
                                color="link" 
                                style="color: red;"
                                @click="openModalEvent('deleteDepartment', 'confirm', item, item.label + ' Sil', item.label+' isimli departmanı silmek istediğinize emin misiniz?')"> 
                                Sil 
                            </CButton>
                        </td>
                    </template>

                    <template #label="{item}">
                        <td>
                            <CInput v-model="item.label" />
                        </td>
                    </template>

                </CDataTable>
            </CForm>
        </CTab>
        <ConfirmModal
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="crud"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
            :loading="loading"
        />
    </CTabs>
</template>

<script>

    import ConfirmModal from '../../components/confirmModal'

    export default{
        name: "careerPositionForm",
        components:{ ConfirmModal },
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
       
        computed:{
            careerPositions: function() {
                return this.$store.getters.careerPositions
            },
            departments: function() {
                let data = [];
                this.$store.getters.careerDepartments.map( async (cd) => {
                    await data.push({label: cd.name, value: cd.id })
                });
                return data
            },
            loading: function() {
                return this.$store.getters.comboLoading
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};

            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                lastItem: null,
                openModal: false,
                form: '',
                modalProps: {},
                modalTitle: '',
                modalDesc: '',
                modalAction: '',
                fields: [
                    {key: 'name', label: 'Pozisyon', _style: 'font-size:12px'},
                    {key: 'department', label: 'Departman', _style: 'font-size:12px'},
                    {key: 'show_details', label: 'İşlemler', _style: 'font-size:12px'},
                ],
                departmentsFields: [
                    {key: 'label', label: 'Departman', _style: 'font-size:12px'},
                    {key: 'show_details', label: 'İşlemler', _style: 'font-size:12px'},
                ]
            }
        },
        methods:{
            rowClicked: function(item){
                this.lastItem = item;
                this.data.name = item.name
            },

            openModalEvent(action, form, data, title, desc){
                this.modalAction = action;
                this.openModal = !this.openModal
                this.form = form
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },

            refresh: async function(){
                await this.$store.dispatch('careerPosition_list')
                await this.$store.dispatch('careerDepartments_list')
            },

            savePosition: async function(){
                var formData = new FormData();
                formData.append('name', this.data.name)
                formData.append('departmentId', this.data.departmentId)

                await this.$store.dispatch('careerPosition_add', formData)
               
                if(this.$store.getters.careerStatus.success) {
                    await this.$store.dispatch('careerPosition_list')
                    this.data.name = '';
                }
            },

            saveDepartment: async function(){
                var formData = new FormData();
                formData.append('name', this.data.departmentName)

                await this.$store.dispatch('careerDepartment_add', formData)
               
                if(this.$store.getters.careerStatus.success) {
                    this.refresh()

                    this.data.departmentName = '';
                }
            },

            crud: async function(){
                var formData = new FormData();

                if(this.modalAction == 'delete'){
                    
                    formData.append('id', this.lastItem.id)

                    await this.$store.dispatch('careerPosition_delete', formData)

                }else if(this.modalAction == 'update') {

                    formData.append('id', this.lastItem.id)
                    formData.append('name', this.lastItem.name)
                    formData.append('departmentId', this.lastItem.departmentId)
                
                    await this.$store.dispatch('careerPosition_update', formData)

                }else if(this.modalAction == 'deleteDepartment'){

                    formData.append('id', this.lastItem.value)

                    await this.$store.dispatch('careerDepartment_delete', formData)

                }else if(this.modalAction == 'updateDepartment'){

                    formData.append('id', this.lastItem.value)
                    formData.append('name', this.lastItem.label)
                
                    await this.$store.dispatch('careerDepartment_update', formData)
                }
               
                if(this.$store.getters.careerStatus.success) {
                    
                    await this.refresh()

                    this.data.name = '';
                    this.openModalEvent();
                }
            }
        }
    }
</script>